<template>
  <div class="home">
    <h2>Scheduling</h2>

    <v-snackbar
        top
        multi-line
        :color="snackbar.color"
        v-model="snackbar.visible"
    >
      {{ snackbar.text }}
    </v-snackbar>

    <v-row class="mt-3" dense>
      <v-col cols="12" md="6">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Facility Name"
          type="input"
          v-model="evaluation.providerName"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" md="3">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="County ID"
          type="input"
          v-model="evaluation.providerCountyId"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="County Name"
          type="input"
          v-model="evaluation.providerCounty"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" md="3">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Project Number"
          type="input"
          v-model="evaluation.evaluationNumber"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Provider Type"
          type="input"
          v-model="evaluation.providerType"
        />
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="12" md="3">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Survey Type ID"
          type="input"
          v-model="evaluation.surveyType"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Survey Type"
          type="input"
          v-model="evaluation.surveyTypeName"
        />
      </v-col>
    </v-row>

    <v-row dense v-if="evaluation">
      <v-col cols="12" md="3">
        <v-radio-group
          row
          label="Initial: "
          :disabled="isReadOnly()"
          v-model="evaluation.initialIndicator"
        >
          <v-radio label="Yes" :value="'I'"/>
          <v-radio label="No" :value="null"/>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="3">
        <v-radio-group
          row
          label="CHOW: "
          :disabled="isReadOnly()"
          v-model="evaluation.chowIndicator"
        >
          <v-radio label="Yes" :value="'Y'"></v-radio>
          <v-radio label="No" :value="null"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row class="mt-3" dense>
      <v-col cols="12" md="3">
        <v-text-field
            rounded
            label="Aspen Event ID"
            type="input"
            v-model="evaluation.aspenEvent"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
            rounded
            label="Schedule Start"
            type="date"
              v-model="evaluation.scheduleStart"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
            rounded
            label="Schedule End"
            type="date"
            v-model="evaluation.scheduleEnd"
        />
      </v-col>
      <v-col cols="12" md="3">
        <!--
        <v-select
            rounded
            label="Team Lead"
            v-model="evaluation.teamLead"
            :items="teamLeadCandidates"
        />
        -->
        <v-autocomplete
            rounded
            label="Team Lead"
            v-model="evaluation.teamLead"
            :items="teamLeadCandidates"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="save" color="primary">
          Save
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="print" color="primary">
          Print
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {evalApi} from "@/plugins/axios";
import schedulingPrintPage from "@/components/SchedulingPrintPage.vue";
import Vue from "vue";

  export default {
    props: {
      evaluation: Object
    },

    data() {
      return {
        teamLeadCandidates: [],
        snackbar: {
          visible: false,
          color: "#2a602c",
          text: ""
        },
      }
    },

    created() {
      this.loadTeamLeadCandidates();
    },

    methods: {
      loadTeamLeadCandidates() {
        evalApi.get(`/evaluationsImport/jiraWorklogUsers`)
          .then(res => {
            this.teamLeadCandidates = res.data.sort();
          })
          .catch(error => console.log(error.message));
      },

      save() {
        this.evaluation.jobName = "EvaluationsImport";

        evalApi.post(`/evaluationsImport/dataImport`, { evaluationList: [this.evaluation] })
          .then(() => {
            this.snackbar.color = "#2a602c";
            this.snackbar.text = "Scheduling information has been saved.";
            this.snackbar.visible = true;
          })
          .catch(error => {
            this.snackbar.color = "#b60000";
            this.snackbar.text = "An error occurred during saving process: " + error;
            this.snackbar.visible = true;
          });
      },

      print() {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Scheduling Print View</title></head><body>');
        printWindow.document.write(new Vue({
          render: h => h(schedulingPrintPage, { props: { evaluation: this.evaluation } })
        }).$mount().$el.outerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        //printWindow.print();
      },

      formatDate(date) {
        return date != null ? new Date(date).toISOString().split('T')[0] : null
      },

      isReadOnly() {
        return false
        /*
        if (this.evaluation.endDate != null) {
          let now = new Date()
          let expDate = new Date(this.evaluation.endDate)
          expDate.setMonth(expDate.getMonth() + 2)
          return now > expDate
        }
        return false
         */
      }
    }
  }
</script>

<style>

.text-black input {
  color: black !important;
}

</style>