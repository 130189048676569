export class SurveyType {
    surveyTypeId?: any;
    surveyTypeDesc?: any;
    surveyType?: any;
    commonProjectFlag?: any;
    surveyStatus?: any;
    expReportFlag?: any;
    surveyClassification?: any;
    budgetPool?: any;
    nfIcfmrFlag ?: any;
    insertUid?: any;
    insertDate?: any;
    modifyUid?: any;
    modifyDate?: any;


}