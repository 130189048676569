<template>
  <div>
    <img src="../shared/logo-mdh-mn-h-blu_rgb.png"
        height="42" width="300"
        style="margin-top: 30px;margin-left:0px;display: block;"/>
    <div>
      <h1 style="margin-top: 20px;margin-bottom: 0px">Pre-Scheduling Document:</h1>
      <p style="margin-top: 5px">DRIVER DOCUMENT FOR ALL EVALUATIONS AND FOLLOW-UP EVALUATIONS FOR PERCEPTIVE CONTENT</p>
    </div>
    <div>
      <h1 style="margin-bottom: 0px">Purpose</h1>
      <p style="margin-top: 5px">Schedulers and evaluators use this document to help select, assign, and schedule evaluations.
        Have Paradise extract data into form fields or calculate as needed to complete the information below and export into a PDF document to capture an Perceptive Content.</p>
    </div>
    <table>
      <tr>
        <td col span="1" style="padding-right:20px;padding-bottom:10px"><b>Evaluation Number: </b>{{evaluation.evaluationNumber}}</td>
        <td col span="1" style="padding-right:20px;padding-bottom:10px"><b>PCR: </b>{{evaluation.revisitNumber}}</td>
        <td col span="1" style="padding-bottom:10px"><b>ACO Event ID #: </b>{{evaluation.aspenEvent}}</td>
      </tr>
      <tr>
        <td style="padding-bottom:10px"><b>Provider County ID: </b>{{evaluation.providerCountyId}}</td>
      </tr>
      <tr>
        <td style="padding-bottom:10px"><b>Provider County: </b>{{evaluation.providerCounty}}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td style="padding-bottom:10px"><b>Provider Name: </b>{{evaluation.providerName}}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td style="padding-bottom:10px"><b>Provider Type: </b>{{evaluation.providerType}}</td>
      </tr>
      <tr>
        <td style="padding-bottom:10px"><b>Survey Type: </b>{{evaluation.surveyType}}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td style="padding-bottom:10px"><b>Survey Type Name: </b>{{evaluation.surveyTypeName}}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td col span="1" style="padding-right:20px;padding-bottom:10px">
          <div v-if="evaluation.initialIndicator"><b>Initial Indicator: </b>Yes</div>
          <div v-else><b>Initial Indicator: </b>No</div>
        </td>
        <td col span="1" style="padding-bottom:10px">
          <div v-if="evaluation.chowIndicator"><b>CHOW Indicator: </b>Yes</div>
          <div v-else><b>CHOW Indicator: </b>No</div>
        </td>
      </tr>
    </table>
    <table>
      <tr>
        <td style="padding-right:20px;padding-bottom:10px"><b>Schedule Start: </b>{{formattedStartDate}}</td>
        <td style="padding-right:20px;padding-bottom:10px"><b>Schedule End: </b>{{formattedEndDate}}</td>
        <td style="padding-right:20px;padding-bottom:10px"><b>Team Lead: </b>{{evaluation.teamLead}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
//import Vuetify from 'vuetify/lib';
export default {
  props: {
    evaluation: Object
  },

  computed: {
    formattedStartDate() {
      if(this.evaluation.scheduleStart) {
        const parts = this.evaluation.scheduleStart.split('-');
        const formattedDate = new Date(parts[0], parts[1] - 1, parts[2]);
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        };
        return formattedDate.toLocaleDateString('en-US', options)
      } else return ''
    },

    formattedEndDate() {
      if(this.evaluation.scheduleEnd) {
        const parts = this.evaluation.scheduleEnd.split('-');
        const formattedDate = new Date(parts[0], parts[1] - 1, parts[2]);
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        };
        return formattedDate.toLocaleDateString('en-US', options)
      } else return ''
    }
  }
};
</script>

<style type="text/css">
@media print {
  header,
  footer {
    display: none;
  }
}

</style>