import {Component, Vue, Watch} from 'vue-property-decorator';
import {FundingType} from "../../shared/model/FundingType";
import {evalApi} from "../../plugins/axios";
import {DataOptions} from "vuetify";
import {EvaluationType} from "../../shared/model/EvaluationType";
import {ProviderType} from "../../shared/model/ProviderType";
import {Flag} from "../../shared/model/Flag";
import {rules} from "@/shared/model/Rules";


@Component({
    components: {},
})
export default class FundingTypeList extends Vue {
    maskText = "";
    search = "";
    valid = true;
    fundingTypeSearch: FundingType = new FundingType();
    evaluationTypes = EvaluationType.values;
    providerTypes = ProviderType.values;
    surveyTypes: Array<String> = [];
    flags = ["Y","N"];
    requiredRule = rules;
    snackbar = {
        visible: false,
        color: "success",
        text: ""
    };

    public fundingTypeList: Array<FundingType> = new Array<FundingType>();
    public options: DataOptions = {
        itemsPerPage: 15,
        page: 1,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    }
    public totalElements: any = 0
    public dialog = false
    public confirmdelete = false
    public isLoading = true
    public srIsLoading = true
    public editedIndex = -1;
    public editedItem = {
        surveyType: "",
        fundingTypeId: "",
        fundingType: "",
        stateFlag: "",
        stateOnlyFlag: "",
        finDeptId: ""
    }
    public defaultItem = {
        surveyType: "",
        fundingTypeId: "",
        fundingType: "",
        stateFlag: "",
        stateOnlyFlag: "",
        finDeptId: ""
    }
    public srCountdown = 4;

    public created() {
        this.loadTable()
    }

    public mounted() {
        const self = this
        document.addEventListener('keypress', function(ev) {
            if (ev.key === 'Enter' && self.$route.name === "FundingTypeList") {
                self.loadTable()
            }
        })
        this.srOnlyLoad()
    }

    @Watch("options",
        {deep: true}
    )
    public loadTable(): void {
        evalApi.post(`/fundingType/search`, {
            fundingTypeId: this.fundingTypeSearch.fundingTypeId,
            fundingType: this.fundingTypeSearch.fundingType,
            stateFlag: this.fundingTypeSearch.stateFlag,
            stateOnlyFlag: this.fundingTypeSearch.stateOnlyFlag,
            finDeptId: this.fundingTypeSearch.finDeptId,
            page: this.options.page,
            size: this.options.itemsPerPage,
        }).then((res: any) => {
        this.fundingTypeList = res.data.content;
        this.totalElements = res.data.totalSize;
        this.isLoading = false;
        })
    }

    public srOnlyLoad(): void {
        if(this.isLoading && this.srCountdown > 0){
            setTimeout(() => {
                this.srCountdown -= 1
                this.srOnlyLoad()
            }, 1000)

        }
        else if(this.isLoading) {
            this.srCountdown = 4
            this.srIsLoading = !this.srIsLoading
            this.srOnlyLoad()
        }
        else {
            this.srIsLoading = false
        }
    }

    public editItem(item: any) {
        this.editedIndex = this.fundingTypeList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    }


    public save(): void {
        evalApi.post("/fundingType/update", {
            fundingTypeId: this.editedItem.fundingTypeId,
            fundingType: this.editedItem.fundingType,
            stateFlag: this.editedItem.stateFlag,
            stateOnlyFlag: this.editedItem.stateOnlyFlag,
            inseruid: "hrdtimetracking_owner",
            insertdate: new Date()
        }).then(res => {
            this.snackbar.color = "success"
            this.snackbar.text = "Funding Type " + this.editedItem.fundingTypeId + " has been updated."
            this.snackbar.visible = true
            this.loadTable()
        }).catch(error => {
            this.snackbar.color = "error"
            this.snackbar.text = "An error occurred updating the funding type: >>>> " + error
            this.snackbar.visible = true
        })
        this.close()
    }

    public close(): void {
        this.dialog = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
    }

    public closeDelete(): void {
        this.confirmdelete = false
    }

    public headers = [
        {text: "Actions", value: "actions", sortable: false},
        {text: "Funding Type ID", value: "fundingTypeId", sortable: false},
        {text: "Funding Type", value: "fundingType", sortable: false},
        {text: "State Flag", value: "stateFlag", sortable: false},
        {text: "State Only Flag", value: "stateOnlyFlag", sortable: false},
        {text: "Fin Dept ID", value: "finDeptId", sortable: false}
    ];

}