export class FundingType {
    fundingTypeId?: any;
    fundingType?: any;
    stateFlag?: any;
    stateOnlyFlag?: any;
    finDeptId?: any;
    insertUid?: any;
    insertDate?: any;
    modifyUid?: any;
    modifyDate?: any;
}