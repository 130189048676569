import {Component, Vue, Watch} from 'vue-property-decorator';
import {evalApi} from "../../plugins/axios";
import {DataOptions} from "vuetify";
import {SurveyType} from "../../shared/model/SurveyType";
import {Flag} from "../../shared/model/Flag";
import {Status} from "@/shared/model/Status";
import {BudgetPool} from "@/shared/model/BudgetPool";
import {rules} from "@/shared/model/Rules";


@Component({
    components: {},
})
export default class SurveyTypeList extends Vue {
    maskText = "";
    search = "";
    valid = true;
    surveyTypeSearch: SurveyType = new SurveyType();
    surveyTypes: Array<SurveyType> = [];
    statuses = Status.values;
    projFlags = ["Common Project Activities","Non Common Project Activities"];
    flags = ["Y","N"];
    budgetPools = BudgetPool.values.sort();
    requiredRule = rules;
    snackbar = {
        visible: false,
        color: "success",
        text: ""
    };

    public surveyTypeList: Array<SurveyType> = new Array<SurveyType>();
    public options: DataOptions = {
        itemsPerPage: 15,
        page: 1,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    }
    public headers = [
        {text: "Actions", value: "actions", sortable: false},
        {text: "Survey Type ID", value: "surveyTypeId", sortable: false},
        {text: "Survey Type Desc", value: "surveyTypeDesc", sortable: false},
        {text: "Survey Type", value: "surveyType", sortable: false},
        {text: "Common Project Flag", value: "commonProjectFlag", sortable: false},
        {text: "Survey Status", value: "surveyStatus", sortable: false},
        {text: "Exp Report Flag", value: "expReportFlag", sortable: false},
        {text: "Budget Pool", value: "budgetPool", sortable: false},
        {text: "NF Icfmr Flag", value: "nfIcfmrFlag", sortable: false},
        {text: "Survey Classification", value: "surveyClassification", sortable: false}
    ]
    public totalElements: any = 0
    public dialog = false
    public confirmdelete = false
    public isLoading = true
    public srIsLoading = true
    public editedIndex = -1;
    public editedItem = {
        surveyTypeId: "",
        surveyTypeDesc: "",
        surveyType: "",
        commonProjectFlag: "",
        surveyStatus: "",
        expReportFlag: "",
        surveyClassification: "",
        nfIcfmrFlag: "",
        budgetPool: ""
    }
    public defaultItem = {
        surveyTypeId: "",
        surveyTypeDesc: "",
        surveyType: "",
        commonProjectFlag: "",
        surveyStatus: "",
        expReportFlag: "",
        surveyClassification: "",
        nfIcfmrFlag: "",
        budgetPool: ""
    }
    public srCountdown = 4;

    public created() {
        this.loadTable()
    }

    public mounted() {
        const self = this
        document.addEventListener('keypress', function(ev) {
            if (ev.key === 'Enter' && self.$route.name === "SurveyTypeList") {
                self.loadTable()
            }
        })
        this.srOnlyLoad()
    }

    @Watch("options",
        {deep: true}
    )
    public loadTable(): void {
        evalApi.post(`/surveyType/search`, {
            surveyTypeId: this.surveyTypeSearch.surveyTypeId,
            surveyTypeDesc: this.surveyTypeSearch.surveyTypeDesc,
            surveyType:this.surveyTypeSearch.surveyType,
            commonProjectFlag:this.surveyTypeSearch.commonProjectFlag,
            surveyStatus: this.surveyTypeSearch.surveyStatus,
            expReportFlag: this.surveyTypeSearch.expReportFlag,
            budgetPool: this.surveyTypeSearch.budgetPool,
            nfIcfmrFlag: this.surveyTypeSearch.nfIcfmrFlag,
            surveyClassification: this.surveyTypeSearch.surveyClassification,
            page: this.options.page,
            size: this.options.itemsPerPage,
         }).then((res: any) => {
        this.surveyTypeList = res.data.content;
        this.totalElements = res.data.totalSize;
        this.isLoading = false;
        })
    }

    public srOnlyLoad(): void {
        if(this.isLoading && this.srCountdown > 0){
            setTimeout(() => {
                this.srCountdown -= 1
                this.srOnlyLoad()
            }, 1000)

        }
        else if(this.isLoading) {
            this.srCountdown = 4
            this.srIsLoading = !this.srIsLoading
            this.srOnlyLoad()
        }
        else {
            this.srIsLoading = false
        }
    }

    public editItem(item: any) {
        this.editedIndex = this.surveyTypeList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    }

    public save(): void {
        evalApi.post("/surveyType/update", {
            surveyTypeId: this.editedItem.surveyTypeId,
            surveyTypeDesc: this.editedItem.surveyTypeDesc,
            surveyType: this.editedItem.surveyType,
            commonProjectFlag: this.editedItem.commonProjectFlag,
            surveyStatus: this.editedItem.surveyStatus,
            expReportFlag: this.editedItem.expReportFlag,
            budgetPool: this.editedItem.budgetPool,
            nfIcfmrFlag: this.editedItem.nfIcfmrFlag,
            surveyClassification: this.editedItem.surveyClassification,
            insertuid: "hrdtimetracking_owner",
            insertdate: new Date()
        }).then(res => {
            this.snackbar.color = "success"
            this.snackbar.text = "Survey Type " + this.editedItem.surveyTypeId + " has been updated."
            this.snackbar.visible = true
            this.loadTable()
        }).catch(error => {
            this.snackbar.color = "error"
            this.snackbar.text = "An error occurred updating the survey type: >>>> " + error
            this.snackbar.visible = true
        })
        this.close()
    }

    public close(): void {
        this.dialog = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
    }

}